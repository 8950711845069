.blog-blog-field {
    background: #000000;
    position: relative;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.blog-picture-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    opacity:0.75;
    object-fit: cover;
}

.blog-picture-container:hover {
    cursor: pointer;
    opacity: 0.5;
}

.blog-picture-name {
    cursor: pointer;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;

    position: absolute;
    /*bottom: 10%;*/
    /*left: 5%;*/
    bottom: 2%;
    left: 5%;
    text-align: left;
    padding-right: 40px;
}

.blog-picture-date {
    display: none;
    cursor: pointer;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    text-align: center;

    position: absolute;
    bottom: 2%;
    left: 5%;
}

.blog-picture-arrow-right {
    cursor: pointer;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    text-align: center;

    position: absolute;
    bottom: 2%;
    right: 5%;
}

@media (max-width: 1025px) {
}

@media (max-width: 769px) {
    .blog-picture-container {
        height: 428px;
    }
}

@media (max-width: 481px) {
}