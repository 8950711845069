.equipment-page {
    padding: 94px 15% 108px 10%;
}

.equipment-main-info-container {
    display: flex;
    margin-bottom: 30px;
    width: 100%;
    justify-content: space-between;
}

.equipment-img {
    width: 40%;
}

.equipment-main-info {
    display: block;
}

.equipment-header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 48px;
    color: #3F3E3F;
}

.equipment-main-article {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #3F3E3F;

    margin-top: 12px;
    margin-bottom: 62px;
    max-width: 380px;
}

.equipment-article {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
    color: #3F3E3F;
    margin-bottom: 48px;
}

.equipment-characteristics-container {
    margin-bottom: 98px;
}

.equipment-characteristic-header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 48px;
    color: #3F3E3F;
}

.equipment-characteristics {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 18px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #3F3E3F;
}

.equipment-characteristic {
    display: flex;
    column-gap: 18px;
}

@media (max-width: 1367px) {
    .equipment-page {
        padding: 94px 5% 108px 5%;
    }
}



@media (max-width: 1025px) {
    .equipment-page {
        padding: 10px 30px 10px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .equipment-main-info {
        margin-right: 0;
    }
    .equipment-container {
        width: 100%;
    }
}

@media (max-width: 769px) {
    .equipment-page {
        padding: 10px 10px 10px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .equipment-main-info-container {
        display: flex;
        flex-direction: column;
    }
    .equipment-main-article {
        margin-bottom: 20px;
    }
    .equipment-characteristics {
        grid-template-columns: auto;
    }
    .equipment-img {
        height: 100%;
        width: 100%;
    }
}

@media (max-width: 481px) {
    .equipment-page {
        padding: 10px 10px 10px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .equipment-img {
        height: 100%;
        width: 100%;
    }
}
