.custom-input-placeholder-align-center {
    text-align: center;
}

.custom-input-placeholder-align-center::-webkit-input-placeholder {
    text-align: center;
}

.custom-input-placeholder-align-center:-ms-input-placeholder {
    text-align: center;
}

.custom-input-placeholder-align-center::placeholder {
    text-align: center;
}

.custom-input-placeholder-align-center:-ms-input-placeholder {
    text-align: center;
}

.custom-input {
    border: 0 solid transparent;
    padding: 12px 46px 12px;


    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 15px;
    color: #3F3E3F;
}

.custom-input:focus {
    outline: 1px solid #000000;
}

.custom-input.error {
    border: 1px solid #FF3031;
}

.custom-input::-webkit-input-placeholder { /* Edge */
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #CBCCCB;
}

.custom-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #CBCCCB;
}

.custom-input::placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #CBCCCB;
}

.custom-input:-ms-input-placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #CBCCCB;
}


@media (max-width: 1025px) {
}

@media (max-width: 769px) {
    .custom-input {
        padding: 10px 28px 10px;
    }
}

@media (max-width: 481px) {
    .custom-input {
        padding: 8px 24px 8px;
    }
}