.tuning {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 36px;
    padding-bottom: 56px;
    background-color: #FFFFFF;
}

.tuning-header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    color: #3F3E3F;
    margin-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
}

.tuning-article {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #3F3E3F;
    margin-bottom: 47px;
    padding-left: 10px;
    padding-right: 10px;
}


.main-page-file-service {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 176px;
    padding-bottom: 126px;

    background-image: url('../../../../assets/background/file-service.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.tuning-iframe {
    min-height: 130px;
}

@media (max-width: 906px) {
    .tuning-iframe {
        min-height: 190px;
    }
}

@media (max-width: 684px) {
    .tuning-iframe {
        min-height: 260px;
    }
}

@media (max-width: 461px) {
    .tuning-iframe {
        min-height: 380px;
    }
}