.request-modal-window:before {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    background-color: #000000;
    opacity: 0.4;
    z-index: 99999;
}

.request-modal {
    padding: 30px 10px 20px 10px;
    position: fixed;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);

    width: 70%;
    height: 70%;
    background-color: #EFEEEF;
    z-index: 999999;
    opacity: 1;
    overflow-x: auto;
}

.request-modal-logo {
}

.request-modal-close-icon {
    position: fixed;
    right: 20px;
    top: 20px;
    -webkit-tap-highlight-color: transparent;
}

.request-modal-close-icon:hover {
    cursor: pointer;
    opacity: 0.6;
}

.request-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.request-modal-input-container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    align-self: normal;
    padding-left: 20%;
    padding-right: 20%;

    margin-top: 24px;
    margin-bottom: 24px;
}

.request-modal-input-container .error-block {
    background-color: #eca5a5;
    border: 1px solid #FF3031;
    border-radius: 5px;
    padding: 15px;
}

.request-modal-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.request-modal-header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    color: #3F3E3F;
    margin-top: 34px;
    margin-bottom: 30px;
}

.request-modal-article {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-align: center;

    padding-left: 20%;
    padding-right: 20%;
}

@media (max-width: 1367px) {
    .request-modal {
        width: 80%;
        height: 80%;
    }
}

@media (max-width: 1025px) {
    .request-modal {
        width: 90%;
        height: 80%;
    }
    .request-modal-input-container {
        padding-left: 15%;
        padding-right: 15%;
    }

    .request-modal-article {
        padding-left: 15%;
        padding-right: 15%;
    }
}

@media (max-width: 769px) {
    .request-modal {
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        padding-top: 0;
    }
    .request-modal-input-container {
        padding-left: 10%;
        padding-right: 10%;
    }

    .request-modal-article {
        padding-left: 10%;
        padding-right: 10%;
    }
    .request-modal-article {
        font-size: 12px;
    }
    .request-modal-header {
        font-size: 36px;
    }
    .request-modal-logo {
        margin-top: 20px;
    }
}

@media (max-width: 481px) {
}