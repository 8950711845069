.price {
    padding: 12px 15px 12px 30px;
    background-color: #FFFFFF;
}

.price-container {
    display: grid;
    grid-template-columns: auto max-content max-content;
    column-gap: 50px;
    cursor: pointer;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
}

.price-name {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    color: #3F3E3F;
}

.price-price {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #3F3E3F;
}

.price-action {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 20px;
    text-align: right;
    color: #3F3E3F;
    width: 20px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */


}


.price-expanded-container {
    display: grid;
    grid-template-columns: auto max-content;
    column-gap: 30px;
    margin-top: 24px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
}


.price-expanded-article {
    height: 100%;
    flex: 1;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #3F3E3F;
}

.price-expanded-btn-container {
    height: 100%;
    align-self: center;
}



@media (max-width: 1025px) {
}

@media (max-width: 769px) {
    .price-name {
        font-size: 12px;
        line-height: 16px
    }
    .price-price {
        font-size: 12px;
        line-height: 16px
    }

    .price-action {
        font-size: 24px;
        line-height: 16px;
    }

    .price-expanded-article {
        font-size: 11px;
        line-height: 16px
    }

    .price-expanded-container {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    .price-expanded-btn-container {
        align-self: flex-end;
    }

    .price-container {
        column-gap: 20px;
    }
}

@media (max-width: 481px) {
    .price-name {
        font-size: 12px;
        line-height: 16px
    }
    .price-price {
        font-size: 12px;
        line-height: 16px
    }

    .price-action {
        font-size: 20px;
        line-height: 16px
    }

    .price-expanded-article {
        font-size: 10px;
        line-height: 16px
    }

    .price-container {
        column-gap: 15px;
    }
}

