.partner {
    background-color: #FFFFFF;
    width: 100%;
    margin-bottom: 20px;
}

.partner-header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 16px;
    color: #3F3E3F;

    margin-bottom: 30px;
    width: 100%;
    text-align: center;
}

.partner-article {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #3F3E3F;

    width: 100%;
    text-align: center;
}

.partner-container {
    padding: 20px 10px 10px;
}

@media (max-width: 1025px) {
}

@media (max-width: 769px) {
    .partner-header {
        font-size: 20px;
    }

    .partner-article {
        font-size: 14px;
    }
}

@media (max-width: 481px) {
}