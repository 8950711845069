.blog {
    background-color: #E5E5E5;
    padding: 50px 150px 150px;
}

.blog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blogs-header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 48px;
    color: #3F3E3F;
    margin-bottom: 20px;
    display: none;
}

.blog-blogs-container {
    margin-top: 72px;
    margin-bottom: 58px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 30px;
    column-gap: 30px;
    width: 100%;
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: visible;
    opacity: 1;
}

.popup {
    margin: 150px auto;
    padding: 40px 20px 20px;
    background: #fff;
    border-radius: 5px;
    width: 800px;
    position: relative;
    transition: all 5s ease-in-out;
}

.popup .close {
    position: absolute;
    top: 5px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}
.popup .close:hover {
    color: #06D85F;
}
.popup .img-content {
    /*max-height: 30%;*/
    overflow: auto;
}

.popup img {
    max-width: 800px;
}

@media (max-width: 1681px) {
    .blog-blogs-container {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 1367px) {
    .blog {
        padding: 30px 50px 50px;
    }
    .blog-blogs-container {
        grid-template-columns: 1fr 1fr;
    }
}


@media (max-width: 1025px) {
    .blog {
        padding: 30px 50px 50px;
    }
    .blog-blogs-container {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 769px) {
    .blog {
        padding: 20px 30px 30px;
    }
    .blog-blogs-container {
        grid-template-columns: auto;
    }
    .blog-blogs-container {
        margin-top: 30px;
    }

    .blogs-header {
        font-size: 16px;
        line-height: 32px;
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }

    .popup {
        width: 80%!important;
    }

    .popup img {
        max-width: 100%!important;
    }

}

@media (max-width: 481px) {
    .blog {
        padding: 10px 10px 10px 10px;
    }

    .blog-blogs-container {
        margin-top: 20px;
    }
    .blogs-header {
        font-size: 14px;
        line-height: 28px;
        margin-top: 10px;
    }

    .popup {
        width: 80%!important;
    }

    .popup img {
        max-width: 100%!important;
    }
}