.main-page-file-service-header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    color: #3F3E3F;
    margin-bottom: 52px;
}

.main-page-file-service-article {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #3F3E3F;
    margin-bottom: 78px;
}

@media (max-width: 769px) {
    .main-page-file-service-header  {
        line-height: 39px;
        font-size: 36px;
        margin-bottom: 39px;
    }

    .main-page-file-service-article {
        font-size: 11px;
        line-height: 12px;
        margin-bottom: 58px;
    }
}

@media (max-width: 481px) {
    .main-page-file-service-header  {
        line-height: 29px;
        font-size: 24px;
        margin-bottom: 52px;
    }

    .main-page-file-service-article {
        font-size: 10px;
        line-height: 12px;
        margin-bottom: 39px;
    }
}
