.blog-page {
    padding: 10px 20% 100px;
    background: #E5E5E5;
}

.blog-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.blog-header {
    margin-top: 88px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #3F3E3F;
}

.blog-image {
    width: 100%;
}

.blog-article {
    margin-top: 55px;

    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    text-align: left;
}
.blog-article p {
    padding-top: 16px;
}

@media (max-width: 1025px) {
    .blog-page {
        padding: 10px 15% 30px 15%;
    }
}

@media (max-width: 769px) {
    .blog-page {
        padding: 10px 20px 20px 20px;
    }
    .blog-header {
        margin-top: 20px;
    }
    .blog-article {
        margin-top: 20px;
    }
}

@media (max-width: 481px) {
    .blog-page {
        padding: 10px 10px 10px 10px;
    }
}