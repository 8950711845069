.prices-page {
    padding: 50px 25% 50px 25%;
    background-color: #E5E5E5;
}

.prices-container {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.prices-header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 48px;
    color: #3F3E3F;
    align-self: center;
}

.prices-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 12px;
    margin-top: 42px;
    align-self: center;
}

@media (max-width: 1025px) {
    .prices-page {
        padding: 30px 100px 50px 100px;
    }
    .prices-content {
        width: 100%;
    }
}

@media (max-width: 769px) {
    .prices-page {
        padding: 15px 15px 15px 15px;
    }
    .prices-header {
        font-size: 16px;
        line-height: 40px
    }
    .prices-content {
        margin-top: 20px;
    }
}

@media (max-width: 481px) {
    .prices-page {
        padding: 10px 10px 10px 10px;
    }
    .prices-header {
        font-size: 16px;
        line-height: 40px
    }
    .prices-content {
        margin-top: 15px;
    }
}