.custom-button {
    background-color: #FF3031;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    border: 2px solid #FF3031;
    overflow:hidden;
    white-space: nowrap;
    cursor: pointer;
}

.custom-button:hover {
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    background-color: transparent;
    color: #FF3031;
}

.custom-button-large {
    padding: 14px 100px;
}

.custom-button-small {
    padding: 8px 36px;
}

.custom-button-modal {
    padding: 10px 80px;
}

@media (max-width: 769px) {
    .custom-button {
        font-size: 14px;
        line-height: 20px;
    }
    .custom-button-small {
        padding: 8px 24px;
    }

    .custom-button-large {
        padding: 14px 75px;
    }
}

@media (max-width: 481px) {
    .custom-button {
        font-size: 12px;
        line-height: 20px;
    }

    .custom-button-small {
        padding: 6px 18px;
    }

    .custom-button-large {
        padding: 11px 40px;
    }
}

