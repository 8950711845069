.footer {
    white-space: nowrap;
    padding-top: 70px;
    padding-bottom: 30px;
    width: 100%;

    background-color: #3F3E3F;
    font-family: Montserrat;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.footer-partner-name {
    font-size: 20px;
    font-weight: 700;
}

.footer-content {
    margin-left: 150px;
    margin-right: 150px;
}

.footer-content a {
    color: white;
    text-decoration: none;
}

.contact-info {
    font-size: 20px;
    font-weight: 700;
}

.contact-info-container {
    display: flex;
    flex-direction: column;
    margin-top: 37px;
    margin-bottom: 24px;
    row-gap: 20px;
    line-height: 24px;
}

.footer-center {
    justify-content: center;
}

.footer-partners-container {
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 20px;
    column-gap: 50px;
}

.footer-line {
    height: 2px;
    margin-right: 85px;
    margin-left: 85px;
    margin-bottom: 27px;
    background-color: #FFFFFF;
}

.footer-additional-info {
    display: flex;
    margin-left: 150px;
    margin-right: 150px;
}

.footer-additional-info-element {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
}

.footer-copyright-content {
    display: flex;
    column-gap: 16px;
}


.footer-additional-info-copyright {
    margin-right: 21px;
}

.footer-logo-container {
    display: flex;
    column-gap: 50px;
    align-items: center;
    margin-bottom: 40px;
}

.footer-icon-link-mms {

}

.footer-icon-link {
    width: 24px;
    height: 24px;
}

.text-strong {
    font-weight: 700;
}

.footer-imaged-text {
    display: inline-block;
}

.footer-imaged-container {
    display: flex;
    align-items: center;
    column-gap: 16px;
}

@media (max-width: 1367px) {
    .footer-partners-container {
        grid-template-columns: auto max-content;
    }
}

@media (max-width: 1025px) {
    .footer-line {
        margin-right: 20px;
        margin-left: 20px;
    }

    .footer {
        white-space: nowrap;
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .footer-content {
        margin-left: 20px;
        margin-right: 20px;
    }

    .footer-additional-info {
        margin-left: 20px;
        margin-right: 20px;
    }
}


@media (max-width: 769px) {
    .footer {
        font-size: 14px;
        line-height: 16px;
    }

    .footer-partner-name {
        font-size: 16px;
    }

    .footer-partners-container {
        grid-template-columns: auto;
    }

    .footer-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 20px;
        margin-left: 0;
    }

    .contact-info-container {
        margin-bottom: 20px;
        width: 100%;
    }
}

@media (max-width: 481px) {
    .footer {
        font-size: 12px;
        line-height: 14px;
    }

    .footer-partner-name {
        font-size: 14px;
    }

    .footer-additional-info {
        flex-direction: column;
        row-gap: 20px;
    }
}
