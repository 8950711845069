.header {
    font-family: Montserrat;
    position: fixed;
    width: 100%;

    z-index: 9999;
    background-color: #3F3E3F;
    color: #FFFFFF;
    min-height: 88px;
}

.header-content__phones {
    margin: 0 25px;
    /*font-size: 13px;*/
}

.header-content__phone {
    color: #FFFFFF;
    text-decoration: none;
    display: block;
    white-space: nowrap;
    margin: 0;
    padding: 2px 0;
}

.header-content__phone-city {
    display: inline-block;
    font-weight: bold;
    width: 70px;
}

.header-burger-content__phones {
    padding: 20px 0;
    text-align: center;
}

.header-burger-content__phone {
    font-style: italic;
    color: #FFFFFF;
    text-decoration: none;
    display: block;
    white-space: nowrap;
    margin: 0;
    padding: 4px 0;
}

.header-burger-content__phone-city {
    display: inline-block;
    font-weight: bold;
}

.main-logo {
}

.main-logo:hover {
    cursor: pointer;
}

.header-content {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding-top: 24px;
    padding-bottom: 24px;
    margin-left: 30px;
    overflow: hidden;
    height: 100%;
}

.header-link-container {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.header-link-container > .header-li {
    margin-left: 14px;
    margin-right: 14px;
}

.header-link-container > .header-li-selected {
    margin-left: 14px;
    margin-right: 14px;
}

.header-burger {
    display: none;
    cursor: pointer;
    margin-right: 10px;
    -webkit-tap-highlight-color: transparent;
}

.header-burger:hover {
    opacity: 0.8;
}

@media (max-width: 1025px) {
    .header-burger {
        display: block;
    }

    .header-link-container {
        display: none;
    }

    .header-content {
        justify-content: space-between;
    }
}

.header-li {
    border-bottom: 1px solid transparent;
}

.header-li-selected {
    border-bottom: 1px solid #FF3031;
}

.header-li:hover {
    border-bottom: 1px solid #3b0b0c;
}

.header-link {
    display: block;
    color: #FFFFFF;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;
    text-decoration: none;
}

.header-burger-container {
    background-color: #2a2a2a;
    right: 0;
    top: 0;
    width: 475px;
    position: fixed;
    height: 100%;
    z-index: 9999;
}


.header-burger-content {
    margin-left: 40px;
    margin-top: 10px;
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    row-gap: 10px;


    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}

.header-burger-img-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #FFFFFF;
}

.burger-close-icon {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.burger-close-icon:hover {
    opacity: 0.5;
}

@media (max-width: 481px) {
    .header-burger-container {
        width: 100%;
    }

    .header-burger-content {
        margin-left: 10px;
        margin-top: 10px;
        margin-right: 10px;
    }

    .header-content__phones {
        display: none;
    }
}
