.about-us {
    width: 100%;
    background-color: #3F3E3F;
}

.about-us-container {
    display: grid;
    height: 100%;
    grid-template-columns: 50% 50%;
}

.about-us-img {
    background-image: url('../../../../assets/background/about-us.jpg');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: left;
}

.about-us-info {
    background-color: #3F3E3F;
    padding: 30px 30px 30px 94px;
}


@media (max-width: 1025px) {
    .about-us-container {
        grid-template-columns: auto;
    }
    .about-us-img {
        display: none;
    }
    .about-us {
        height: auto;
    }

    .about-us-info {
        padding-top: 50px;
    }
}

.about-us-question {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    color: #FFFFFF;
    margin-bottom: 28px;
}

.about-us-who-we-are {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 30px;
}

.about-us-our-capabilities {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    color: #FFFFFF;
    margin-top: 76px;
}

.about-us-additional-info {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 56px;
    margin-bottom: 80px;
}

@media (max-width: 769px) {
    .about-us-info {
        padding: 50px 22px 22px 40px
    }
    .about-us-question {
        font-size: 36px;
        line-height: 37px;
        margin-bottom: 21px;
    }
    .about-us-who-we-are {
        line-height: 18px;
        font-size: 12px;
        margin-bottom: 23px;
    }
    .about-us-our-capabilities {
        font-size: 36px;
        margin-top: 57px;
        line-height: 43px;
    }
    .about-us-additional-info {
        font-size: 12px;
        margin-top: 42px;
        margin-bottom: 60px;
        line-height: 18px;
    }
}

@media (max-width: 481px) {
    .about-us-info {
        padding: 30px 15px 15px 30px
    }
    .about-us-question {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 14px;
    }
    .about-us-who-we-are {
        line-height: 12px;
        font-size: 10px;
        margin-bottom: 16px;
    }
    .about-us-our-capabilities {
        font-size: 24px;
        margin-top: 38px;
        line-height: 30px;
    }
    .about-us-additional-info {
        font-size: 10px;
        margin-top: 28px;
        margin-bottom: 40px;
        line-height: 12px;
    }
}