.blog {
    background-color: #E5E5E5;
    padding: 50px 150px 150px;
}

.blog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blogs-header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 48px;
    color: #3F3E3F;
    margin-bottom: 20px;
    display: none;
}

.blog-search-container {
    /*display: grid;*/
    grid-template-columns: auto min-content;
    column-gap: 30px;
    width: 100%;
    display: none;
}

.blog-blogs-container {
    margin-top: 72px;
    margin-bottom: 58px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 30px;
    column-gap: 30px;
    width: 100%;
}

@media (max-width: 1681px) {
    .blog-blogs-container {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 1367px) {
    .blog {
        padding: 30px 50px 50px;
    }
    .blog-blogs-container {
        grid-template-columns: 1fr 1fr;
    }
}


@media (max-width: 1025px) {
    .blog {
        padding: 30px 50px 50px;
    }
    .blog-blogs-container {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 769px) {
    .blog {
        padding: 20px 30px 30px;
    }
    .blog-blogs-container {
        grid-template-columns: auto;
    }
    .blog-search-container {
        display: none;
        /*display: flex;*/
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
    }
    .blog-blogs-container {
        margin-top: 30px;
    }

    .blogs-header {
        font-size: 16px;
        line-height: 32px;
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }

}

@media (max-width: 481px) {
    .blog {
        padding: 10px 10px 10px 10px;
    }

    .blog-blogs-container {
        margin-top: 20px;
    }
    .blogs-header {
        font-size: 14px;
        line-height: 28px;
        margin-top: 10px;
    }
}