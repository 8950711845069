.partners-page {
    padding: 50px 25% 50px 25%;
    background-color: #E5E5E5;
}

.partners-header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 48px;
    color: #3F3E3F;
    margin-bottom: 30px;
    width: 100%;
    text-align: center;
}


@media (max-width: 1025px) {
    .partners-page {
        padding: 30px 100px 30px 100px;
    }
}

@media (max-width: 769px) {
    .partners-page {
        padding: 15px 15px 15px 15px;
    }
    .partners-header {
        font-size: 20px;
    }
}

@media (max-width: 481px) {
    .partners-page {
        padding: 10px 10px 10px 10px;
    }
}

