.file-service-page {
    padding: 50px 150px 150px;
}

.file-service-header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 48px;
    color: #3F3E3F;
    margin-bottom: 30px;
}

.file-service-content {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    text-align: left;
    color: #3F3E3F;
    margin-bottom: 50px;
}

@media (max-width: 1025px) {
    .file-service-page {
        padding: 30px 100px 100px;
    }
}

@media (max-width: 769px) {
    .file-service-page {
        padding: 20px 30px 30px;
    }
    .file-service-header {
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 40px;
        text-align: center;
    }
    .file-service-content {
        font-size: 12px;
        line-height: 26px;
        margin-bottom: 20px;
    }
    .file-service-btn-block {
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 481px) {
    .file-service-page {
        padding: 10px 15px 15px;
    }
}
