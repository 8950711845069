.custom-pagination {
    width: 100%;
    /*display: flex;*/
    display: none;
    justify-content: space-between;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #3F3E3F;
}

.pagination-pages {
    display: flex;
    column-gap: 20px;
}

.pagination-vector {
    display: flex;
    column-gap: 20px;
}

@media (max-width: 1025px) {
}

@media (max-width: 769px) {
    .pagination-vector {
        display: flex;
        column-gap: 10px;
    }

    .custom-pagination {
        font-size: 12px;
        line-height: 12px;
    }
}

@media (max-width: 481px) {
    .pagination-direction {
        display: none;
    }
}