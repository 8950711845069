.equipments-page {
    padding: 50px 15% 150px 15%;
    background-color: #E5E5E5;
}

.equipments-block {
    display: grid;
    width: 100%;
    grid-template-columns: auto min-content;
    /*column-gap: 64px;*/
}

.equipments-content {
    display: flex;
    margin-top: 20px;
    row-gap: 24px;
}

.equipments-content {
    display: grid;
    width: 100%;
    grid-template-columns: auto;
}

.equipments-filter-header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 48px;
    color: #3F3E3F;
}

.equipments-filter {
    display: none;
    padding-left: 30px;
    border-left: 1px solid #CBCCCB;
}


@media (max-width: 1025px) {
    .equipments-page {
        padding: 30px 100px 100px;
    }
}

@media (max-width: 769px) {
    .equipments-page {
        padding: 20px 20px 20px 20px;
    }
}

@media (max-width: 481px) {
    .equipments-page {
        padding: 15px 15px 15px 15px;
    }
}