.equipment-component {
    background-color: #FFFFFF;
    height: 348px;

    padding: 10px 30px 20px;
}

.equipment-component-container {
    display: grid;
    grid-template-columns: auto max-content;
    column-gap: 95px;

    height: 100%;
}

.equipment-component-info {
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    height: 100%;
}

.equipment-component-header-container {
    display: flex;
    align-items: center;

    justify-content: space-between;
}

.equipment-component-header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 48px;
    color: #3F3E3F;
}

.equipment-component-price {
    margin-left: 30px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: right;

    color: #3F3E3F;
}

.equipment-component-main-article {
    margin-top: 20px;
    margin-bottom: 16px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #3F3E3F;
}

.equipment-component-direct-link {
    cursor: pointer;
    display: block;
    margin-bottom: 36px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #3F3E3F;

    text-decoration: underline;
}

.equipment-component-image-container {
}
.equipment-component-image {
    width: 320px;
    height: 100%;
    object-fit: cover;
}


@media (max-width: 1025px) {
    .equipment-component {
        height: unset;
    }

    .equipment-component-container {
        display: flex;
        flex-direction: column-reverse;
    }

    .equipment-component-image-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .equipment-component-info {
        justify-content: unset;
        height: unset;
    }
    .equipment-component-image {
        width: 100%;
    }
}

@media (max-width: 769px) {

}

@media (max-width: 481px) {

}