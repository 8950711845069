.faq-page {
    padding: 50px 150px 50px 150px;
}

.faq-header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 48px;
    color: #3F3E3F;
}



.faq-article {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    text-align: left;
    color: #3F3E3F;
    margin-bottom: 50px;
}


@media (max-width: 1025px) {
    .faq-page {
        padding: 30px 100px 30px 100px
    }
}

@media (max-width: 769px) {
    .faq-page {
        padding: 15px 50px 15px 50px
    }
    .faq-header {
        font-size: 16px;
    }
    .faq-article {
        font-size: 14px;
    }
}

@media (max-width: 481px) {
    .faq-page {
        padding: 10px 20px 10px 20px
    }
}
