.custom-select {
    border: 0 solid transparent;
    padding: 12px 0 12px 20px;

    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;
    -ms-text-align-last: center;
    text-align-all: center;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 15px;
    color: #3F3E3F;
}

.custom-select option {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 15px;
    color: #3F3E3F;
}

.custom-select:focus {
    outline: 1px solid #000000;
}

@media (max-width: 1025px) {
}

@media (max-width: 769px) {
    .custom-select {
        padding: 10px 0 10px 15px;
    }
}

@media (max-width: 481px) {
    .custom-select {
        padding: 8px 0 8px 15px;
    }
}